<template>
  <v-menu v-model="menu" offset-y >
    <template v-slot:activator="{ on }">
      <div v-on="on" style="position:relative; flex-grow: 1;" class="object-selector" :class="{'object-selector-full': valueObj !== null}">
        <div class="v-list" v-if="valueObj" style="position:absolute; width: 100%; padding: 0">
            <div class="v-list-item" v-if="valueObj" style="padding: 0">
                <slot name="item" :item="valueObj"></slot>
            </div>
        </div>
        <v-text-field
            @blur="blur"
            :label="label"
            v-model="search"
            clearable
            style="line-height: 100px;"
        />
      </div>
    </template>
    <v-list>
      <v-subheader v-if="items.length == 0">No {{objectNamePlural}} found.</v-subheader>
      <v-list-item v-for="item in items" :key="item.id" @click="selected(item)">
        <slot name="item" :item="item"></slot>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ['value', 'label', 'itemFunc', 'suggestionsFunc', 'objectName', 'objectNamePlural', 'valueSelector'],
  data() {
    return {
      valueObj: null,
      menu: false,
      menuOnType: false,
      search: '',
      items: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.valueObj = null;
        } else if (this.valueObj === null || this.valueObj.id !== val) {
          this.itemFunc(val).then((data) => {
            this.valueObj = data;
          }, () => {
            this.valueObj = null;
            this.$emit('input', null);
          });
        }
      },
    },
    search: {
      immediate: true,
      handler(val) {
        if (val) {
          this.valueObj = null;
          this.$emit('input', null);
        }
        this.querySelections(val);
        if (this.menuOnType) {
          this.menu = true;
        }
        this.menuOnType = true;
      },
    },
  },
  methods: {
    blur() {
      if (this.search && this.items.length === 1) {
        const item = this.items[0];
        this.valueObj = item;
        this.$emit('input', item.id);
      }
      this.search = '';
    },
    selected(item) {
      this.search = '';
      this.valueObj = item;

      if (this.valueSelector) {
        this.$emit('input', this.valueSelector(item));
      } else {
        this.$emit('input', item.id);
      }
    },
    querySelections(v) {
      this.loading = true;
      const suggestions = this.suggestionsFunc(v);
      if (suggestions) {
        suggestions.then((items) => {
          this.items = items;
          this.loading = false;
        });
      }
    },
    changed(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    currencies() {
      if (!this.$global.currencies) {
        return [];
      }
      return this.$global.currencies.map((c) => ({ value: c.id, text: c.name }));
    },
  },
};
</script>

<style lang="scss">
.object-selector {
    input {
        height: 48px;
        max-height: 48px;
    }
    .input-group--text-field label {
        top: 27px;
    }
    &.object-selector-full label,
    .input-group--text-field.input-group--dirty label,
    .input-group--text-field.input-group--focused label {
        -webkit-transform: translateY(-27px) scale(.75)!important;
        transform: translateY(-27px) scale(.75)!important;
    }
}
</style>
